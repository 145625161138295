"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRequestEditPrivateMemo = exports.useUpdateUserInfo = void 0;
var react_1 = require("react");
var vrcpToVrc_1 = require("../../api/utils/vrcpToVrc");
var api_1 = require("../api");
var util_1 = require("../util");
function isFleshPrivateInfo(um) {
    var _a;
    return (!!((_a = um.profile) === null || _a === void 0 ? void 0 : _a.privateMemoUpdatedAt) &&
        // 2分以内に更新
        new Date(um.profile.privateMemoUpdatedAt).getTime() > new Date().getTime() - 120000);
}
function useUpdateUserInfo(_a) {
    var requestUpdateUserInfo = _a.requestUpdateUserInfo, setRequestUpdateUserInfo = _a.setRequestUpdateUserInfo, updatedUserInfo = _a.updatedUserInfo, setUpdatedUserInfo = _a.setUpdatedUserInfo, onlineFriends = _a.onlineFriends, offlineFriends = _a.offlineFriends, favoriteFriends = _a.favoriteFriends, setOnlineFriends = _a.setOnlineFriends, setOfflineFriends = _a.setOfflineFriends, setFavoriteFriends = _a.setFavoriteFriends;
    (0, react_1.useEffect)(function () {
        if (!requestUpdateUserInfo)
            return;
        (0, api_1.fetchUserById)(requestUpdateUserInfo, true).then(function (user) {
            setUpdatedUserInfo(user);
        });
    }, [requestUpdateUserInfo]);
    // とりあえずnoteのみアプデ
    (0, react_1.useEffect)(function () {
        if (!updatedUserInfo)
            return;
        var _a = (0, util_1.applyToUserMany)({ onlineFriends: onlineFriends, offlineFriends: offlineFriends, favoriteFriends: favoriteFriends }), replaceById = _a.replaceById, apply = _a.apply;
        replaceById(updatedUserInfo.id, function (um) {
            var _a;
            return ((_a = um.profile) === null || _a === void 0 ? void 0 : _a.privateMemoIsSynced)
                ? isFleshPrivateInfo(um) || (0, vrcpToVrc_1.vrcMemoIsSame)(updatedUserInfo.note, um.profile.privateMemo)
                    ? um
                    : __assign(__assign({}, um), { note: updatedUserInfo.note, profile: __assign(__assign({}, um.profile), { privateMemo: updatedUserInfo.note }) })
                : __assign(__assign({}, um), { note: updatedUserInfo.note });
        });
        apply({
            onlineFriends: onlineFriends,
            offlineFriends: offlineFriends,
            favoriteFriends: favoriteFriends,
            setOnlineFriends: setOnlineFriends,
            setOfflineFriends: setOfflineFriends,
            setFavoriteFriends: setFavoriteFriends,
        });
        setUpdatedUserInfo(undefined);
        setRequestUpdateUserInfo(undefined);
    }, [updatedUserInfo]);
}
exports.useUpdateUserInfo = useUpdateUserInfo;
function useRequestEditPrivateMemo(_a) {
    var requestEditProfilePrivateInfo = _a.requestEditProfilePrivateInfo, setRequestEditProfilePrivateInfo = _a.setRequestEditProfilePrivateInfo, updatedEditProfilePrivateInfo = _a.updatedEditProfilePrivateInfo, setUpdatedEditProfilePrivateInfo = _a.setUpdatedEditProfilePrivateInfo, setEditProfilePrivateInfo = _a.setEditProfilePrivateInfo, setTempPrivateMemo = _a.setTempPrivateMemo, onlineFriends = _a.onlineFriends, offlineFriends = _a.offlineFriends, favoriteFriends = _a.favoriteFriends, setOnlineFriends = _a.setOnlineFriends, setOfflineFriends = _a.setOfflineFriends, setFavoriteFriends = _a.setFavoriteFriends;
    (0, react_1.useEffect)(function () {
        if (!requestEditProfilePrivateInfo)
            return;
        (0, api_1.fetchUserById)(requestEditProfilePrivateInfo, true).then(function (user) {
            setUpdatedEditProfilePrivateInfo(user);
        });
    }, [requestEditProfilePrivateInfo]);
    // とりあえずnoteのみアプデ
    (0, react_1.useEffect)(function () {
        var _a;
        if (!updatedEditProfilePrivateInfo)
            return;
        var _b = (0, util_1.applyToUserMany)({ onlineFriends: onlineFriends, offlineFriends: offlineFriends, favoriteFriends: favoriteFriends }), replaceById = _b.replaceById, apply = _b.apply, getById = _b.getById;
        replaceById(updatedEditProfilePrivateInfo.id, function (um) {
            var _a;
            return ((_a = um.profile) === null || _a === void 0 ? void 0 : _a.privateMemoIsSynced)
                ? isFleshPrivateInfo(um) || (0, vrcpToVrc_1.vrcMemoIsSame)(updatedEditProfilePrivateInfo.note, um.profile.privateMemo)
                    ? um
                    : __assign(__assign({}, um), { note: updatedEditProfilePrivateInfo.note, profile: __assign(__assign({}, um.profile), { privateMemo: updatedEditProfilePrivateInfo.note }) })
                : __assign(__assign({}, um), { note: updatedEditProfilePrivateInfo.note });
        });
        var um = getById(updatedEditProfilePrivateInfo.id);
        apply({
            onlineFriends: onlineFriends,
            offlineFriends: offlineFriends,
            favoriteFriends: favoriteFriends,
            setOnlineFriends: setOnlineFriends,
            setOfflineFriends: setOfflineFriends,
            setFavoriteFriends: setFavoriteFriends,
        });
        setEditProfilePrivateInfo(updatedEditProfilePrivateInfo.id);
        setTempPrivateMemo((_a = um === null || um === void 0 ? void 0 : um.profile) === null || _a === void 0 ? void 0 : _a.privateMemo);
        setUpdatedEditProfilePrivateInfo(undefined);
        setRequestEditProfilePrivateInfo(undefined);
    }, [updatedEditProfilePrivateInfo]);
}
exports.useRequestEditPrivateMemo = useRequestEditPrivateMemo;
