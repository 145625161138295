"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserBase = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var React = __importStar(require("react"));
var semantic_ui_react_1 = require("semantic-ui-react");
var WorldStatus_1 = require("./WorldStatus");
var PlatformMark_1 = require("./PlatformMark");
var UserStatus_1 = require("./UserStatus");
var TrustMark_1 = require("./TrustMark");
var cardTextStyle = { wordWrap: "break-word", overflowWrap: "break-word" };
exports.UserBase = React.memo(function (_a) {
    var user = _a.user, main = _a.main, footer = _a.footer, meta = _a.meta, world = _a.world, worldLoading = _a.worldLoading, color = _a.color;
    return ((0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Card, __assign({ color: color }, { children: [(0, jsx_runtime_1.jsx)(UserImage, { src: user.profilePicOverride || user.currentAvatarThumbnailImageUrl }, void 0), (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Card.Content, { children: [(0, jsx_runtime_1.jsx)(UserNames, { username: user.username, displayName: user.displayName, lastPlatform: user.last_platform }, void 0), meta && (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Card.Meta, { children: meta }, void 0), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Card.Description, __assign({ style: cardTextStyle }, { children: main }), void 0)] }, void 0), (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Card.Content, __assign({ extra: true, style: cardTextStyle }, { children: [(0, jsx_runtime_1.jsx)(UserStatus_1.UserStatus, { status: user.status, statusDescription: user.statusDescription }, void 0), (0, jsx_runtime_1.jsx)(TrustMark_1.TrustMark, { tags: user.tags }, void 0)] }), void 0), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Card.Content, __assign({ extra: true, style: cardTextStyle }, { children: (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Grid, __assign({ columns: 2 }, { children: [(0, jsx_runtime_1.jsx)(semantic_ui_react_1.Grid.Column, __assign({ floated: "left" }, { children: world !== false && (0, jsx_runtime_1.jsx)(WorldStatus_1.WorldStatus, __assign({ world: world, loading: worldLoading }, user.locationInfo), void 0) }), void 0), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Grid.Column, __assign({ floated: "right", textAlign: "right" }, { children: footer }), void 0)] }), void 0) }), void 0)] }), void 0));
});
var UserImage = React.memo(function (_a) {
    var src = _a.src;
    return (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Image, { src: src }, void 0);
});
var UserNames = React.memo(function (_a) {
    var displayName = _a.displayName, username = _a.username, lastPlatform = _a.lastPlatform;
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Card.Header, __assign({ style: cardTextStyle }, { children: [displayName, (0, jsx_runtime_1.jsx)(PlatformMark_1.PlatformMark, { platform: lastPlatform }, void 0)] }), void 0), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Card.Meta, __assign({ style: cardTextStyle }, { children: username }), void 0)] }, void 0));
});
