"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.World = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var semantic_ui_react_1 = require("semantic-ui-react");
var react_router_dom_1 = require("react-router-dom");
var react_1 = require("react");
var cardTextStyle = { wordWrap: "break-word", overflowWrap: "break-word" };
exports.World = (0, react_1.memo)(function World(props) {
    var world = props.world;
    return ((0, jsx_runtime_1.jsx)(semantic_ui_react_1.Card, { children: (0, jsx_runtime_1.jsxs)(semantic_ui_react_1.Card.Content, { children: [(0, jsx_runtime_1.jsx)(semantic_ui_react_1.Item.Image, { floated: "left", size: "tiny", src: world.thumbnailImageUrl }, void 0), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Card.Header, __assign({ style: cardTextStyle }, { children: (0, jsx_runtime_1.jsx)(react_router_dom_1.Link, __assign({ to: "/w/" + world.id }, { children: world.name }), void 0) }), void 0), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Card.Meta, __assign({ style: cardTextStyle }, { children: world.authorName }), void 0), (0, jsx_runtime_1.jsx)(semantic_ui_react_1.Card.Description, { children: world.description }, void 0)] }, void 0) }, void 0));
});
