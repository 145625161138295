"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchUserNotes = exports.removeUserFromUserDefinedGroup = exports.putUserToUserDefinedGroup = exports.removeUserDefinedGroup = exports.updateUserDefinedGroup = exports.createUserDefinedGroup = exports.fetchUserDefinedGroups = exports.deleteUserSearchParam = exports.saveUserSearchParam = exports.fetchUserSearchParams = exports.searchUserIdAndUsernamesByTagNames = exports.unlockUserProfileTags = exports.lockUserProfileTags = exports.saveUserProfileTags = exports.fetchTags = exports.fetchPlayerModerations = exports.fetchUsersByNames = exports.fetchUserByName = exports.fetchUserById = exports.migrateProfilePrivateMemoByNote = exports.migrateProfilePrivateMemo = exports.syncEndProfilePrivateMemo = exports.syncProfilePrivateMemo = exports.saveProfilePrivateMemo = exports.saveProfile = exports.fetchFavoriteFriends = exports.fetchFriends = exports.fetchProfile = exports.fetchProfiles = exports.fetchProfilesByUsers = void 0;
var util_1 = require("../util");
var fetch_1 = require("./fetch");
var base_1 = require("./base");
var fetchProfilesByUsers = function (users, state) {
    return (0, exports.fetchProfiles)(users.map(function (user) { return user.id; }), state);
};
exports.fetchProfilesByUsers = fetchProfilesByUsers;
var fetchProfiles = function (ids, state) { return __awaiter(void 0, void 0, void 0, function () {
    var url, uniqIdsHash, _i, ids_1, id, useIds, response, userProfiles;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!ids.length)
                    return [2 /*return*/, []];
                url = new URL(base_1.origin + "/api/user_profiles/many");
                uniqIdsHash = {};
                for (_i = 0, ids_1 = ids; _i < ids_1.length; _i++) {
                    id = ids_1[_i];
                    uniqIdsHash[id] = true;
                }
                useIds = Object.keys(uniqIdsHash);
                return [4 /*yield*/, (0, fetch_1.post)(url.href, { body: new URLSearchParams({ ids: useIds.join(" ") }) })];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, (0, base_1.extractJsonOrThrow)(response, "fetch profiles error")];
            case 2:
                userProfiles = (_a.sent()).userProfiles;
                return [2 /*return*/, (0, util_1.withPartTime)(userProfiles)];
        }
    });
}); };
exports.fetchProfiles = fetchProfiles;
var fetchProfile = function (id) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
    switch (_a.label) {
        case 0: return [4 /*yield*/, (0, exports.fetchProfiles)([id])];
        case 1: return [2 /*return*/, (_a.sent())[0]];
    }
}); }); };
exports.fetchProfile = fetchProfile;
var fetchFriends = function (state, offline, offset) {
    if (offset === void 0) { offset = 0; }
    return __awaiter(void 0, void 0, void 0, function () {
        var url, response, result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = new URL(base_1.origin + "/api/vrcapi/friends");
                    if (offline)
                        url.searchParams.set("offline", "true");
                    if (offset)
                        url.searchParams.set("offset", offset.toString());
                    return [4 /*yield*/, (0, fetch_1.get)(url.href)];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, (0, base_1.extractJsonOrThrow)(response, "fetch friends error")];
                case 2:
                    result = (_a.sent());
                    return [2 /*return*/, { users: (0, util_1.sortByUsername)(result.users), isMaxSize: result.isMaxSize }];
            }
        });
    });
};
exports.fetchFriends = fetchFriends;
var fetchFavoriteFriends = function (state, tag, offset) {
    if (offset === void 0) { offset = 0; }
    return __awaiter(void 0, void 0, void 0, function () {
        var url, response, result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = new URL(base_1.origin + "/api/vrcapi/friends/favorite");
                    url.searchParams.set("tag", tag);
                    if (offset)
                        url.searchParams.set("offset", offset.toString());
                    return [4 /*yield*/, (0, fetch_1.get)(url.href)];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, (0, base_1.extractJsonOrThrow)(response, "fetch friends error")];
                case 2:
                    result = (_a.sent());
                    return [2 /*return*/, { users: (0, util_1.sortByUsername)(result.users), maxSize: result.isMaxSize }];
            }
        });
    });
};
exports.fetchFavoriteFriends = fetchFavoriteFriends;
var saveProfile = function (state) { return __awaiter(void 0, void 0, void 0, function () {
    var userProfile, url, body, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                userProfile = state.tempProfile;
                if (!userProfile)
                    return [2 /*return*/];
                url = new URL(base_1.origin + "/api/user_profiles");
                body = new URLSearchParams();
                body.set("id", userProfile.id);
                body.set("username", userProfile.username);
                if (userProfile.lockTags != null)
                    body.set("lockTags", userProfile.lockTags.toString());
                if (userProfile.profile)
                    body.set("profile", userProfile.profile);
                if (userProfile.site)
                    body.set("site", userProfile.site);
                if (userProfile.twitter)
                    body.set("twitter", userProfile.twitter);
                if (userProfile.steamProfileId)
                    body.set("steamProfileId", userProfile.steamProfileId);
                if (userProfile.steamCustomUrlId)
                    body.set("steamCustomUrlId", userProfile.steamCustomUrlId);
                if (userProfile.discord)
                    body.set("discord", userProfile.discord);
                if (userProfile.booth)
                    body.set("booth", userProfile.booth);
                if (userProfile.vroidhub)
                    body.set("vroidhub", "" + userProfile.vroidhub);
                if (userProfile.github)
                    body.set("github", userProfile.github);
                if (userProfile.gitlab)
                    body.set("gitlab", userProfile.gitlab);
                if (userProfile.youtube)
                    body.set("youtube", userProfile.youtube);
                if (userProfile.niconico)
                    body.set("niconico", "" + userProfile.niconico);
                if ((0, util_1.isPresentDateString)(userProfile.joinedAt))
                    body.set("joinedAt", userProfile.joinedAt);
                if ((0, util_1.isPresentDateString)(userProfile.birthday))
                    body.set("birthday", userProfile.birthday);
                return [4 /*yield*/, (0, fetch_1.post)(url.href, { body: body })];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, (0, base_1.extractJsonOrThrow)(response, "post profile error")];
            case 2:
                _a.sent();
                return [2 /*return*/];
        }
    });
}); };
exports.saveProfile = saveProfile;
var saveProfilePrivateMemo = function (state) { return __awaiter(void 0, void 0, void 0, function () {
    var url, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!state.editProfilePrivateInfo)
                    return [2 /*return*/];
                url = new URL(base_1.origin + "/api/user_profile_private_memos/" + state.editProfilePrivateInfo);
                return [4 /*yield*/, (0, fetch_1.post)(url.href, { body: new URLSearchParams({ memo: state.tempPrivateMemo || "" }) })];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, (0, base_1.extractJsonOrThrow)(response, "post profile private memo error")];
            case 2:
                _a.sent();
                return [2 /*return*/];
        }
    });
}); };
exports.saveProfilePrivateMemo = saveProfilePrivateMemo;
var syncProfilePrivateMemo = function (notes) { return __awaiter(void 0, void 0, void 0, function () {
    var url, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = new URL(base_1.origin + "/api/user_profile_private_memos/sync");
                return [4 /*yield*/, (0, fetch_1.put)(url.href, {
                        body: JSON.stringify({ notes: notes }),
                        headers: {
                            "Content-Type": "application/json",
                        },
                    })];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, (0, base_1.extractJsonOrThrow)(response, "sync profile private memo error")];
            case 2: return [2 /*return*/, (_a.sent())
                    .updatedUserIds];
        }
    });
}); };
exports.syncProfilePrivateMemo = syncProfilePrivateMemo;
var syncEndProfilePrivateMemo = function (targetUserIds) { return __awaiter(void 0, void 0, void 0, function () {
    var url, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = new URL(base_1.origin + "/api/user_profile_private_memos/syncEnd");
                return [4 /*yield*/, (0, fetch_1.put)(url.href, {
                        body: JSON.stringify({ targetUserIds: targetUserIds }),
                        headers: {
                            "Content-Type": "application/json",
                        },
                    })];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, (0, base_1.extractJsonOrThrow)(response, "sync profile private memo error")];
            case 2: return [2 /*return*/, (_a.sent())
                    .updatedUserIds];
        }
    });
}); };
exports.syncEndProfilePrivateMemo = syncEndProfilePrivateMemo;
var migrateProfilePrivateMemo = function (targetUserId) { return __awaiter(void 0, void 0, void 0, function () {
    var url, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = new URL(base_1.origin + "/api/user_profile_private_memos/" + targetUserId + "/migrate");
                return [4 /*yield*/, (0, fetch_1.post)(url.href)];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, (0, base_1.extractJsonOrThrow)(response, "migrate profile private memo error")];
            case 2:
                _a.sent();
                return [2 /*return*/];
        }
    });
}); };
exports.migrateProfilePrivateMemo = migrateProfilePrivateMemo;
var migrateProfilePrivateMemoByNote = function (targetUserId, note) { return __awaiter(void 0, void 0, void 0, function () {
    var url, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = new URL(base_1.origin + "/api/user_profile_private_memos/" + targetUserId + "/migrateByNote");
                return [4 /*yield*/, (0, fetch_1.post)(url.href, { body: new URLSearchParams({ note: note }) })];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, (0, base_1.extractJsonOrThrow)(response, "migrate profile private memo error")];
            case 2:
                _a.sent();
                return [2 /*return*/];
        }
    });
}); };
exports.migrateProfilePrivateMemoByNote = migrateProfilePrivateMemoByNote;
var fetchUserById = function (id, force) { return __awaiter(void 0, void 0, void 0, function () {
    var url, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = new URL(base_1.origin + "/api/vrcapi/users/" + id);
                if (force)
                    url.searchParams.set("force", "true");
                return [4 /*yield*/, (0, fetch_1.get)(url.href)];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, (0, base_1.extractJsonOrThrow)(response, "fetch user by id error")];
            case 2: return [2 /*return*/, (_a.sent()).user];
        }
    });
}); };
exports.fetchUserById = fetchUserById;
var fetchUserByName = function (username) { return __awaiter(void 0, void 0, void 0, function () {
    var url, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = new URL(base_1.origin + "/api/vrcapi/users/" + username + "/name");
                return [4 /*yield*/, (0, fetch_1.get)(url.href)];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, (0, base_1.extractJsonOrThrow)(response, "fetch user by name error")];
            case 2: return [2 /*return*/, (_a.sent()).user];
        }
    });
}); };
exports.fetchUserByName = fetchUserByName;
var fetchUsersByNames = function (usernames) { return __awaiter(void 0, void 0, void 0, function () {
    var url, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!usernames.length)
                    return [2 /*return*/, []];
                url = new URL(base_1.origin + "/api/vrcapi/users/names");
                return [4 /*yield*/, (0, fetch_1.post)(url.href, { body: new URLSearchParams({ usernames: JSON.stringify(usernames) }) })];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, (0, base_1.extractJsonOrThrow)(response, "fetch users by names error")];
            case 2: return [2 /*return*/, (_a.sent()).users];
        }
    });
}); };
exports.fetchUsersByNames = fetchUsersByNames;
function fetchPlayerModerations() {
    return __awaiter(this, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, (0, fetch_1.get)(base_1.origin + "/api/vrcapi/user/playermoderations")];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, (0, base_1.extractJsonOrThrow)(response, "fetch player moderations error")];
                case 2: return [2 /*return*/, (_a.sent())
                        .playerModerations];
            }
        });
    });
}
exports.fetchPlayerModerations = fetchPlayerModerations;
var fetchTags = function () { return __awaiter(void 0, void 0, void 0, function () {
    var url, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = new URL(base_1.origin + "/api/tags");
                return [4 /*yield*/, (0, fetch_1.get)(url.href)];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, (0, base_1.extractJsonOrThrow)(response, "fetch tags error")];
            case 2: return [2 /*return*/, (_a.sent()).tags];
        }
    });
}); };
exports.fetchTags = fetchTags;
var saveUserProfileTags = function (id, add, remove, state) { return __awaiter(void 0, void 0, void 0, function () {
    var url, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = new URL(base_1.origin + "/api/user_profile_tags/" + id);
                return [4 /*yield*/, (0, fetch_1.patch)(url.href, {
                        body: new URLSearchParams({ add: JSON.stringify(add), remove: JSON.stringify(remove) }),
                    })];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, (0, base_1.extractJsonOrThrow)(response, "save tags error")];
            case 2: return [2 /*return*/, (_a.sent()).tags];
        }
    });
}); };
exports.saveUserProfileTags = saveUserProfileTags;
var lockUserProfileTags = function (name, state) { return __awaiter(void 0, void 0, void 0, function () {
    var url, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = new URL(base_1.origin + "/api/user_profile_tags/lock/" + name);
                return [4 /*yield*/, (0, fetch_1.patch)(url.href)];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, (0, base_1.extractJsonOrThrow)(response, "lock tags error")];
            case 2:
                _a.sent();
                return [2 /*return*/];
        }
    });
}); };
exports.lockUserProfileTags = lockUserProfileTags;
var unlockUserProfileTags = function (name, state) { return __awaiter(void 0, void 0, void 0, function () {
    var url, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = new URL(base_1.origin + "/api/user_profile_tags/unlock/" + name);
                return [4 /*yield*/, (0, fetch_1.patch)(url.href)];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, (0, base_1.extractJsonOrThrow)(response, "unlock tags error")];
            case 2:
                _a.sent();
                return [2 /*return*/];
        }
    });
}); };
exports.unlockUserProfileTags = unlockUserProfileTags;
var searchUserIdAndUsernamesByTagNames = function (tagNames) { return __awaiter(void 0, void 0, void 0, function () {
    var url, response;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!tagNames.length)
                    return [2 /*return*/, []];
                url = new URL(base_1.origin + "/api/user_profile_tags/search/user_profiles");
                url.searchParams.set("names", JSON.stringify(tagNames));
                return [4 /*yield*/, (0, fetch_1.get)(url.href)];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, (0, base_1.extractJsonOrThrow)(response, "search users by tags error")];
            case 2: return [2 /*return*/, (_a.sent()).users];
        }
    });
}); };
exports.searchUserIdAndUsernamesByTagNames = searchUserIdAndUsernamesByTagNames;
function fetchUserSearchParams() {
    return __awaiter(this, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, (0, fetch_1.get)(base_1.origin + "/api/user_search_params")];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, (0, base_1.extractJsonOrThrow)(response, "fetch search params error")];
                case 2: return [2 /*return*/, (_a.sent())
                        .userSearchParams];
            }
        });
    });
}
exports.fetchUserSearchParams = fetchUserSearchParams;
function saveUserSearchParam(name, content) {
    return __awaiter(this, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, (0, fetch_1.post)(base_1.origin + "/api/user_search_params/" + encodeURIComponent(name), {
                        body: JSON.stringify(content),
                        headers: {
                            "Content-Type": "application/json",
                        },
                    })];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, (0, base_1.extractJsonOrThrow)(response, "save search params error")];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
exports.saveUserSearchParam = saveUserSearchParam;
function deleteUserSearchParam(name) {
    return __awaiter(this, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, (0, fetch_1._delete)(base_1.origin + "/api/user_search_params/" + encodeURIComponent(name))];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, (0, base_1.extractJsonOrThrow)(response, "delete search params error")];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
exports.deleteUserSearchParam = deleteUserSearchParam;
function fetchUserDefinedGroups() {
    return __awaiter(this, void 0, void 0, function () {
        var url, response, result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = new URL(base_1.origin + "/api/user_defined_groups");
                    return [4 /*yield*/, (0, fetch_1.get)(url.href)];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, (0, base_1.extractJsonOrThrow)(response, "fetch user defined groups error")];
                case 2:
                    result = (_a.sent()).userDefinedGroups;
                    return [2 /*return*/, result];
            }
        });
    });
}
exports.fetchUserDefinedGroups = fetchUserDefinedGroups;
function createUserDefinedGroup(name) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = new URL(base_1.origin + "/api/user_defined_groups");
                    return [4 /*yield*/, (0, fetch_1.post)(url.href, {
                            body: (0, fetch_1.toBodyData)({ name: name }),
                        })];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, (0, base_1.extractJsonOrThrow)(response, "save user defined group error")];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
exports.createUserDefinedGroup = createUserDefinedGroup;
function updateUserDefinedGroup(id, name) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = new URL(base_1.origin + "/api/user_defined_groups/" + id);
                    return [4 /*yield*/, (0, fetch_1.patch)(url.href, {
                            body: (0, fetch_1.toBodyData)({ name: name }),
                        })];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, (0, base_1.extractJsonOrThrow)(response, "save user defined group error")];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
exports.updateUserDefinedGroup = updateUserDefinedGroup;
function removeUserDefinedGroup(id) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = new URL(base_1.origin + "/api/user_defined_groups/" + id);
                    return [4 /*yield*/, (0, fetch_1._delete)(url.href)];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, (0, base_1.extractJsonOrThrow)(response, "remove user defined group error")];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
exports.removeUserDefinedGroup = removeUserDefinedGroup;
function putUserToUserDefinedGroup(id, userProfileId, primary) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = new URL(base_1.origin + "/api/user_defined_groups/" + id + "/users/" + userProfileId);
                    return [4 /*yield*/, (0, fetch_1.put)(url.href, { body: (0, fetch_1.toBodyData)({ primary: primary }) })];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, (0, base_1.extractJsonOrThrow)(response, "save user defined group user error")];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
exports.putUserToUserDefinedGroup = putUserToUserDefinedGroup;
function removeUserFromUserDefinedGroup(id, userProfileId) {
    return __awaiter(this, void 0, void 0, function () {
        var url, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = new URL(base_1.origin + "/api/user_defined_groups/" + id + "/users/" + userProfileId);
                    return [4 /*yield*/, (0, fetch_1._delete)(url.href)];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, (0, base_1.extractJsonOrThrow)(response, "remove user defined group user error")];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
exports.removeUserFromUserDefinedGroup = removeUserFromUserDefinedGroup;
var fetchUserNotes = function (offset) {
    if (offset === void 0) { offset = 0; }
    return __awaiter(void 0, void 0, void 0, function () {
        var url, response, result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = new URL(base_1.origin + "/api/vrcapi/userNotes");
                    if (offset)
                        url.searchParams.set("offset", offset.toString());
                    return [4 /*yield*/, (0, fetch_1.get)(url.href)];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, (0, base_1.extractJsonOrThrow)(response, "fetch notes error")];
                case 2:
                    result = (_a.sent());
                    return [2 /*return*/, result.notes];
            }
        });
    });
};
exports.fetchUserNotes = fetchUserNotes;
