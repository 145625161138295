"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.vrcMemoIsSame = exports.vrcpToVrc = exports.vrcNoteCanonical = void 0;
var vrcChars = {
    ":": "˸",
    "/": "⁄",
    ".": "․",
    "(": "（",
    ")": "）",
    "#": "＃",
    "+": "＋",
    "@": "＠",
    "!": "ǃ",
    "=": "＝",
    '"': "＂",
    "*": "∗",
    ",": "‚",
    ";": ";",
};
var vrcCharsRe = new RegExp("[" + Object.keys(vrcChars).join("") + "]", "g");
var vrcNoteCanonical = function (note) { return note.replace(/[ \n]+/g, "\0").trim(); };
exports.vrcNoteCanonical = vrcNoteCanonical;
var vrcpToVrc = function (memo) {
    return memo
        .trim()
        .replace(/\r?\n/g, " ")
        // eslint-disable-next-line no-irregular-whitespace
        .replace(/[ 　]+/g, "\0")
        .replace(/[⤴→↑←↓…♥]/g, "")
        .replace(/s\s*h\s*i\s*t/g, "poop")
        .replace(vrcCharsRe, function (c) { return vrcChars[c]; });
};
exports.vrcpToVrc = vrcpToVrc;
var vrcMemoIsSame = function (vrcNote, vrcpMemo) {
    if (!vrcNote || !vrcpMemo)
        return !vrcNote && !vrcpMemo;
    return (0, exports.vrcNoteCanonical)(vrcNote) === (0, exports.vrcpToVrc)(vrcpMemo);
};
exports.vrcMemoIsSame = vrcMemoIsSame;
