"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAllState = exports.friendsFetchIntervalStep = exports.friendsFetchInterval = exports.genSetFavoriteGroupFriends = void 0;
/// <reference path="./gtag.d.ts" />
var React = __importStar(require("react"));
var react_1 = require("react");
var react_router_1 = require("react-router");
var i18n_1 = require("./i18n");
var sentry_1 = require("./sentry");
var useGetFriends_1 = require("./effects/useGetFriends");
var useGetNeedWorlds_1 = require("./effects/useGetNeedWorlds");
var useGetMissingWorlds_1 = require("./effects/useGetMissingWorlds");
var useGetWorldDetail_1 = require("./effects/useGetWorldDetail");
var useGetWorldInstance_1 = require("./effects/useGetWorldInstance");
var useGetGroups_1 = require("./effects/useGetGroups");
var useGetGroupUsers_1 = require("./effects/useGetGroupUsers");
var useFirstGetGroups_1 = require("./effects/useFirstGetGroups");
var useFetchTags_1 = require("./effects/useFetchTags");
var useGetPlayerModerations_1 = require("./effects/useGetPlayerModerations");
var useGetContentsSeries_1 = require("./effects/useGetContentsSeries");
var useGetContentsItems_1 = require("./effects/useGetContentsItems");
var useSyncPrivateMemos_1 = require("./effects/useSyncPrivateMemos");
var useGetUserDefinedGroups_1 = require("./effects/useGetUserDefinedGroups");
var util_1 = require("./util");
var BackupDB_1 = require("./BackupDB");
var useUpdateUserInfo_1 = require("./effects/useUpdateUserInfo");
var genSetFavoriteGroupFriends = function (favoriteFriends, setFavoriteFriends) { return function (group) { return function (users) {
    var newFavoriteFriends = favoriteFriends.slice();
    newFavoriteFriends.splice(group, 1, users);
    setFavoriteFriends(newFavoriteFriends);
}; }; };
exports.genSetFavoriteGroupFriends = genSetFavoriteGroupFriends;
function applyToUser(_a, id, apply) {
    var onlineFriends = _a.onlineFriends, offlineFriends = _a.offlineFriends, favoriteFriends = _a.favoriteFriends, setOnlineFriends = _a.setOnlineFriends, setOfflineFriends = _a.setOfflineFriends, setFavoriteFriends = _a.setFavoriteFriends, searchUsers = _a.searchUsers, setSearchUsers = _a.setSearchUsers, groupUsers = _a.groupUsers, setGroupUsers = _a.setGroupUsers;
    if (!id)
        return;
    var setFavoriteGroupFriends = (0, exports.genSetFavoriteGroupFriends)(favoriteFriends, setFavoriteFriends);
    var sources = __spreadArray(__spreadArray([onlineFriends, offlineFriends], favoriteFriends, true), [searchUsers, groupUsers], false);
    var sets = __spreadArray(__spreadArray([
        setOnlineFriends,
        setOfflineFriends
    ], favoriteFriends.map(function (_, i) { return setFavoriteGroupFriends(i); }), true), [
        setSearchUsers,
        setGroupUsers,
    ], false);
    for (var i = 0; i < sources.length; ++i) {
        var source = sources[i];
        var index = source.map(function (user) { return user.id; }).indexOf(id);
        if (index === -1)
            continue;
        var targetUser = source[index];
        var set = sets[i];
        var newSource = source.slice();
        newSource.splice(index, 1, apply(targetUser));
        set(newSource);
    }
}
exports.friendsFetchInterval = 120;
exports.friendsFetchIntervalStep = 20;
function useLocalStorageState(name, initialState) {
    var _a = (0, react_1.useState)(function () {
        var gotValue = localStorage.getItem(name);
        if (gotValue) {
            return JSON.parse(gotValue);
        }
        return initialState instanceof Function ? initialState() : initialState;
    }), value = _a[0], setValue = _a[1];
    return [
        value,
        function (nextValue) {
            if (nextValue instanceof Function)
                nextValue = nextValue(value);
            localStorage.setItem(name, JSON.stringify(nextValue));
            setValue(nextValue);
        },
    ];
}
var useAllState = function () {
    var _a = (0, react_1.useReducer)(function (prevErrorCount) { return prevErrorCount + 1; }, 0), errorCount = _a[0], _incrementErrorCount = _a[1];
    var incrementErrorCount = _incrementErrorCount;
    var _b = (0, react_1.useReducer)(function (prevErrorCount) { return prevErrorCount + 1; }, 0), errorIgnoredCount = _b[0], _incrementErrorIgnoredCount = _b[1];
    var incrementErrorIgnoredCount = _incrementErrorIgnoredCount;
    var _c = React.useState(false), firstLoginTryed = _c[0], setFirstLoginTryed = _c[1];
    var _d = useLocalStorageState("remember", true), remember = _d[0], setRemember = _d[1];
    var _e = (0, react_1.useState)(false), logined = _e[0], setLogined = _e[1];
    var _f = (0, react_1.useReducer)(function (prevWorlds, nextWorlds) { return (__assign(__assign({}, prevWorlds), nextWorlds)); }, {}), worlds = _f[0], addWorlds = _f[1];
    var _g = (0, react_1.useState)(false), needWorldFetching = _g[0], setNeedWorldFetching = _g[1];
    var _h = (0, react_1.useReducer)(function (prevIds, action) {
        if (action.operation === "push") {
            return prevIds.concat([action.ids]);
        }
        return prevIds.slice(1);
    }, []), needWorldIds = _h[0], dispatchNeedWorldIds = _h[1];
    var _j = (0, react_1.useState)(false), missingWorldFetching = _j[0], setMissingWorldFetching = _j[1];
    var _k = (0, react_1.useReducer)(function (prevIds, action) {
        if (action.operation === "push") {
            // ユニーク性を担保
            var existIdsHash_1 = {};
            for (var i = 0; i < prevIds.length; ++i)
                existIdsHash_1[prevIds[i]] = true;
            return prevIds.concat(action.ids.filter(function (id) {
                var notExists = !existIdsHash_1[id];
                existIdsHash_1[id] = true;
                return notExists;
            }));
        }
        return prevIds.slice(1);
    }, []), missingWorldIds = _k[0], dispatchMissingWorldIds = _k[1];
    var _l = (0, react_1.useState)(undefined), requestDetailWorld = _l[0], setRequestDetailWorld = _l[1];
    var _m = (0, react_1.useReducer)(function (prevWorldInstances, nextWorldInstance) {
        var _a;
        return (__assign(__assign({}, prevWorldInstances), (_a = {}, _a[nextWorldInstance.worldId + ":" + nextWorldInstance.worldInstance.id] = nextWorldInstance.worldInstance, _a)));
    }, {}), worldInstances = _m[0], addWorldInstance = _m[1];
    var _o = (0, react_1.useState)(false), worldInstanceFetching = _o[0], setWorldInstanceFetching = _o[1];
    var _p = (0, react_1.useReducer)(function (prevIds, action) {
        if (action.operation === "push") {
            return prevIds.concat([action.id]);
        }
        return prevIds.slice(1);
    }, []), requestWorldInstanceIds = _p[0], dispatchRequestWorldInstanceIds = _p[1];
    var _q = (0, react_1.useState)({}), selfUser = _q[0], _setSelfUser = _q[1];
    var _r = (0, react_1.useState)(undefined), showProfileUser = _r[0], setShowProfileUser = _r[1];
    var _s = (0, react_1.useState)(false), showProfileUserLoading = _s[0], setShowProfileUserLoading = _s[1];
    var _t = useLocalStorageState("usersPageSize", 40), usersPageSize = _t[0], setUsersPageSize = _t[1];
    var _u = useLocalStorageState("worldsPageSize", 40), worldsPageSize = _u[0], setWorldsPageSize = _u[1];
    var _v = useLocalStorageState("compactView", false), compactView = _v[0], setCompactView = _v[1];
    var _w = useLocalStorageState("sortPerInstance", false), sortPerInstance = _w[0], setSortPerInstance = _w[1];
    var _x = useLocalStorageState("showOnlyUserExistsTab", false), showOnlyUserExistsTab = _x[0], setShowOnlyUserExistsTab = _x[1];
    var _y = (0, react_1.useState)(false), friendsFirstFetched = _y[0], setFriendsFirstFetched = _y[1];
    var _z = (0, react_1.useState)(0), friendsFetchRestInterval = _z[0], setFriendsFetchRestInterval = _z[1];
    var _0 = (0, react_1.useState)(0), friendsOfflineFetchRestInterval = _0[0], setFriendsOfflineFetchRestInterval = _0[1];
    var _1 = (0, react_1.useState)({}), pages = _1[0], setPages = _1[1];
    var _2 = (0, react_1.useState)("wait"), friendsLoadingState = _2[0], setFriendsLoadingState = _2[1];
    var _3 = (0, react_1.useState)({}), onlineFriendsInWorldInstanceIndexes = _3[0], setOnlineFriendsInWorldInstanceIndexes = _3[1];
    var _4 = (0, react_1.useState)(false), onlineFriendsLoading = _4[0], setOnlineFriendsLoading = _4[1];
    var _5 = (0, react_1.useState)([]), onlineFriends = _5[0], setOnlineFriends = _5[1];
    var _6 = (0, react_1.useState)(false), onlineFriendsMax = _6[0], setOnlineFriendsMax = _6[1];
    var _7 = (0, react_1.useState)(false), offlineFriendsLoading = _7[0], setOfflineFriendsLoading = _7[1];
    var _8 = (0, react_1.useState)([]), offlineFriends = _8[0], setOfflineFriends = _8[1];
    var _9 = (0, react_1.useState)(false), offlineFriendsMax = _9[0], setOfflineFriendsMax = _9[1];
    var _10 = (0, react_1.useState)(false), favoriteFriendsLoading = _10[0], setFavoriteFriendsLoading = _10[1];
    var _11 = (0, react_1.useState)([]), favoriteFriends = _11[0], setFavoriteFriends = _11[1];
    var _12 = (0, react_1.useState)([]), favoriteFriendsMax = _12[0], setFavoriteFriendsMax = _12[1];
    var _13 = (0, react_1.useState)(false), notesLoading = _13[0], setNotesLoading = _13[1];
    var _14 = (0, react_1.useState)(false), notesFetched = _14[0], setNotesFetched = _14[1];
    var _15 = (0, react_1.useState)([]), userDefinedGroups = _15[0], setUserDefinedGroups = _15[1];
    var _16 = (0, react_1.useState)(false), userDefinedGroupsFetchRequest = _16[0], setUserDefinedGroupsFetchRequest = _16[1];
    var _17 = (0, react_1.useState)(undefined), editUserDefinedGroupUsers = _17[0], setEditUserDefinedGroupUsers = _17[1];
    var editingUserDefinedGroup = (0, react_1.useMemo)(function () { return userDefinedGroups.filter(function (userDefinedGroup) { return userDefinedGroup.id === editUserDefinedGroupUsers; })[0]; }, [userDefinedGroups, editUserDefinedGroupUsers]);
    var _18 = (0, react_1.useState)(false), searchUsersLoading = _18[0], setSearchUsersLoading = _18[1];
    var _19 = (0, react_1.useState)([]), searchUsers = _19[0], setSearchUsers = _19[1];
    var _20 = (0, react_1.useState)(false), editSelfProfile = _20[0], setEditSelfProfile = _20[1];
    var _21 = React.useState(undefined), tempProfile = _21[0], setTempProfile = _21[1];
    var _22 = (0, react_1.useState)(false), selfProfileSaving = _22[0], setSelfProfileSaving = _22[1];
    var _23 = (0, react_1.useState)(undefined), editTags = _23[0], _setEditTags = _23[1];
    var _24 = (0, react_1.useState)(false), tagsSaving = _24[0], setTagsSaving = _24[1];
    var _25 = (0, react_1.useState)(undefined), requestUpdateUserInfo = _25[0], setRequestUpdateUserInfo = _25[1];
    var _26 = (0, react_1.useState)(undefined), updatedUserInfo = _26[0], setUpdatedUserInfo = _26[1];
    var _27 = (0, react_1.useState)(undefined), requestEditProfilePrivateInfo = _27[0], setRequestEditProfilePrivateInfo = _27[1];
    var _28 = (0, react_1.useState)(undefined), updatedEditProfilePrivateInfo = _28[0], setUpdatedEditProfilePrivateInfo = _28[1];
    var _29 = (0, react_1.useState)(undefined), editProfilePrivateInfo = _29[0], setEditProfilePrivateInfo = _29[1];
    var _30 = (0, react_1.useState)(undefined), tempPrivateMemo = _30[0], setTempPrivateMemo = _30[1];
    var _31 = (0, react_1.useState)([]), tempPrivateTags = _31[0], setTempPrivateTags = _31[1];
    var _32 = (0, react_1.useState)(false), profilePrivateInfoSaving = _32[0], setProfilePrivateInfoSaving = _32[1];
    var search = (0, react_router_1.useLocation)().search;
    var history = (0, react_router_1.useHistory)();
    var _33 = (0, react_1.useReducer)(function updateSearchParam(prev, action) {
        var _a;
        var next = "name" in action
            ? __assign(__assign({}, prev), (_a = {}, _a[action.name] = action.value, _a)) : action;
        history.push({ search: (0, util_1.stringifySearchParamQuery)(next) });
        return next;
    }, search, util_1.parseSearchParamQuery), searchParams = _33[0], _setSearchParams = _33[1];
    var setSearchParams = _setSearchParams;
    var _34 = (0, react_1.useState)([]), savedSearchParams = _34[0], setSavedSearchParams = _34[1];
    var _35 = (0, react_1.useState)(false), savedSearchParamsLoading = _35[0], setSavedSearchParamsLoading = _35[1];
    var _36 = useLocalStorageState("showSearchForm", true), showSearchForm = _36[0], setShowSearchForm = _36[1];
    var _37 = (0, react_1.useState)(undefined), fetchTagsState = _37[0], setFetchTagsState = _37[1];
    var requestFetchTags = (0, react_1.useCallback)(function (force) { return !fetchTagsState && setFetchTagsState(force ? "forceRequest" : "request"); }, [fetchTagsState]);
    var _38 = (0, react_1.useState)([]), tags = _38[0], setTags = _38[1];
    var _39 = (0, react_1.useState)(false), tagLoading = _39[0], setTagLoading = _39[1];
    var _40 = (0, react_1.useState)(undefined), tagsFetchedAt = _40[0], setTagsFetchedAt = _40[1];
    var _41 = (0, react_1.useState)(""), tagValue = _41[0], setTagValue = _41[1];
    var _42 = (0, react_1.useState)([]), tagSuggestions = _42[0], setTagSuggestions = _42[1];
    var _43 = (0, react_1.useState)({}), playerModerations = _43[0], setPlayerModerations = _43[1];
    var _44 = (0, react_1.useState)(false), playerModerationsFetchRequest = _44[0], setPlayerModerationsFetchRequest = _44[1];
    var getUserFirstUnmuteAt = (0, react_1.useCallback)(function (id) {
        var typeMap = playerModerations[id];
        if (!typeMap)
            return;
        var list = typeMap.unmute;
        if (!list)
            return;
        var unmute = list[0];
        if (!unmute)
            return;
        return unmute.createdTime;
    }, [playerModerations]);
    var _45 = (0, react_1.useReducer)(function updateSearchParam(prev, action) {
        var _a;
        if ("value" in action) {
            return __assign(__assign({}, prev), (_a = {}, _a[action.name] = action.value, _a));
        }
        return action;
    }, {}), worldSearchParams = _45[0], _setWorldSearchParams = _45[1];
    var setWorldSearchParams = _setWorldSearchParams;
    var _46 = (0, react_1.useState)([]), savedWorldSearchParams = _46[0], setSavedWorldSearchParams = _46[1];
    var _47 = (0, react_1.useState)(false), savedWorldSearchParamsLoading = _47[0], setSavedWorldSearchParamsLoading = _47[1];
    var _48 = (0, react_1.useState)(""), searchGroupName = _48[0], setSearchGroupName = _48[1];
    var _49 = (0, react_1.useState)([]), groups = _49[0], setGroups = _49[1];
    var _50 = (0, react_1.useState)(false), groupsFirstFetched = _50[0], setGroupsFirstFetched = _50[1];
    var _51 = (0, react_1.useState)(false), groupsLoading = _51[0], setGroupsLoading = _51[1];
    var _52 = (0, react_1.useState)(false), groupsFetchRequest = _52[0], setGroupsFetchRequest = _52[1];
    var _53 = (0, react_1.useState)([]), groupUsers = _53[0], setGroupUsers = _53[1];
    var _54 = (0, react_1.useState)(false), groupUsersLoading = _54[0], setGroupUsersLoading = _54[1];
    var _55 = (0, react_1.useState)(undefined), groupUsersFetchRequest = _55[0], setGroupUsersFetchRequest = _55[1];
    var clearGroupUsers = (0, react_1.useCallback)(function () {
        setGroupUsers([]);
    }, []);
    var _56 = (0, react_1.useState)([]), contentsSeriesList = _56[0], setContentsSeriesList = _56[1];
    var _57 = (0, react_1.useState)([]), contentsItems = _57[0], setContentsItems = _57[1];
    var _58 = (0, react_1.useState)(false), contentsSeriesFirstFetched = _58[0], setContentsSeriesFirstFetched = _58[1];
    var _59 = (0, react_1.useState)(false), contentsItemsFirstFetched = _59[0], setContentsItemsFirstFetched = _59[1];
    var _60 = (0, react_1.useState)(false), contentsSeriesLoading = _60[0], setContentsSeriesLoading = _60[1];
    var _61 = (0, react_1.useState)(false), contentsItemsLoading = _61[0], setContentsItemsLoading = _61[1];
    var _62 = (0, react_1.useState)(false), contentsSeriesFetchRequest = _62[0], setContentsSeriesFetchRequest = _62[1];
    var _63 = (0, react_1.useState)(false), contentsItemsFetchRequest = _63[0], setContentsItemsFetchRequest = _63[1];
    var _64 = (0, react_1.useState)(i18n_1.defaultLang), lang = _64[0], _setLang = _64[1];
    var setLang = (0, react_1.useCallback)(function (nextLang) {
        (0, i18n_1.setLang)(nextLang);
        _setLang(nextLang);
    }, []);
    var worldInstance = (0, react_1.useCallback)(function (worldId, instanceFullId) { return worldInstances[worldId + ":" + instanceFullId]; }, [worldInstances]);
    var setSelfUser = (0, react_1.useCallback)(function (user) {
        _setSelfUser(user);
        (0, sentry_1.setSentryUser)({ id: user.id, username: user.username || user.displayName });
        gtag("set", { user_id: user.id }); // Google Analytics
    }, []);
    var setFriendsFetchRestIntervalMax = (0, react_1.useCallback)(function () { return setFriendsFetchRestInterval(exports.friendsFetchInterval); }, []);
    (0, react_1.useEffect)(function () {
        if (friendsFetchRestInterval <= 0)
            return;
        var tid = setTimeout(function () {
            if (friendsFetchRestInterval > 0)
                setFriendsFetchRestInterval(friendsFetchRestInterval - exports.friendsFetchIntervalStep);
        }, exports.friendsFetchIntervalStep * 1000);
        return function () { return clearTimeout(tid); };
    }, [friendsFetchRestInterval]);
    var setFriendsOfflineFetchRestIntervalMax = (0, react_1.useCallback)(function () { return setFriendsOfflineFetchRestInterval(exports.friendsFetchInterval); }, []);
    (0, react_1.useEffect)(function () {
        if (friendsOfflineFetchRestInterval <= 0)
            return;
        var tid = setTimeout(function () {
            if (friendsOfflineFetchRestInterval > 0)
                setFriendsOfflineFetchRestInterval(friendsOfflineFetchRestInterval - exports.friendsFetchIntervalStep);
        }, exports.friendsFetchIntervalStep * 1000);
        return function () { return clearTimeout(tid); };
    }, [friendsOfflineFetchRestInterval]);
    var setSelfProfile = function (profile) { return setSelfUser(__assign(__assign({}, selfUser), { profile: profile })); };
    var setEditTags = (0, react_1.useCallback)(function (value) {
        _setEditTags(value);
        requestFetchTags();
    }, [requestFetchTags]);
    var setSearchTags = (0, react_1.useCallback)(function (names) {
        setSearchParams({ name: "tags", value: names });
        requestFetchTags();
    }, [requestFetchTags]);
    var applyToUserProps = {
        onlineFriends: onlineFriends,
        offlineFriends: offlineFriends,
        favoriteFriends: favoriteFriends,
        setOnlineFriends: setOnlineFriends,
        setOfflineFriends: setOfflineFriends,
        setFavoriteFriends: setFavoriteFriends,
        searchUsers: searchUsers,
        setSearchUsers: setSearchUsers,
        groupUsers: groupUsers,
        setGroupUsers: setGroupUsers,
    };
    var setUserProfileTag = function (id, nextTags) {
        if (id === selfUser.id) {
            setSelfUser(__assign(__assign({}, selfUser), { profile: __assign(__assign({}, (selfUser.profile || { id: selfUser.id, privateTags: [] })), { tags: nextTags }) }));
        }
        applyToUser(applyToUserProps, id, function (user) { return (__assign(__assign({}, user), { profile: __assign(__assign({}, (user.profile || { id: user.id, privateTags: [] })), { tags: nextTags }) })); });
    };
    var applyProfilePrivateInfo = function () {
        applyToUser(applyToUserProps, editProfilePrivateInfo, function (user) {
            var _a;
            return (__assign(__assign({}, user), { note: !((_a = user.profile) === null || _a === void 0 ? void 0 : _a.privateMemoIsSynced) ? user.note : tempPrivateMemo || "", profile: __assign(__assign({}, (user.profile || { id: user.id, tags: [], privateTags: [] })), { privateMemo: tempPrivateMemo, privateMemoIsSynced: !user.profile || user.profile.privateMemoIsSynced }) }));
        });
    };
    var friendsLoading = onlineFriendsLoading || offlineFriendsLoading || favoriteFriendsLoading || notesLoading;
    var page = (0, react_1.useCallback)(function (name) { return pages[name] || 0; }, [pages]);
    var setPage = (0, react_1.useCallback)(function (name, nextPage) {
        var _pages = __assign({}, pages);
        _pages[name] = nextPage;
        setPages(_pages);
    }, [pages]);
    var allPrivateMemoSynced = (0, react_1.useMemo)(function () { return __spreadArray([onlineFriends, offlineFriends], favoriteFriends, true).every(function (users) { return users.every(util_1.isPrivateMemoSynced); }); }, [onlineFriends, offlineFriends, favoriteFriends]);
    var _65 = (0, react_1.useState)(false), syncPrivateMemo = _65[0], setSyncPrivateMemo = _65[1];
    // [userId, note, synced]
    var _66 = (0, react_1.useState)(), syncingPrivateMemoUserModel = _66[0], setSyncingPrivateMemoUserModel = _66[1];
    var backupDb = (0, react_1.useMemo)(function () { return new BackupDB_1.BackupDB(); }, []);
    var state = {
        errorCount: errorCount,
        incrementErrorCount: incrementErrorCount,
        errorIgnoredCount: errorIgnoredCount,
        incrementErrorIgnoredCount: incrementErrorIgnoredCount,
        firstLoginTryed: firstLoginTryed,
        setFirstLoginTryed: setFirstLoginTryed,
        remember: remember,
        setRemember: setRemember,
        logined: logined,
        setLogined: setLogined,
        worlds: worlds,
        addWorlds: addWorlds,
        needWorldFetching: needWorldFetching,
        setNeedWorldFetching: setNeedWorldFetching,
        needWorldIds: needWorldIds,
        dispatchNeedWorldIds: dispatchNeedWorldIds,
        missingWorldFetching: missingWorldFetching,
        setMissingWorldFetching: setMissingWorldFetching,
        missingWorldIds: missingWorldIds,
        dispatchMissingWorldIds: dispatchMissingWorldIds,
        requestDetailWorld: requestDetailWorld,
        setRequestDetailWorld: setRequestDetailWorld,
        worldInstances: worldInstances,
        addWorldInstance: addWorldInstance,
        worldInstance: worldInstance,
        worldInstanceFetching: worldInstanceFetching,
        setWorldInstanceFetching: setWorldInstanceFetching,
        requestWorldInstanceIds: requestWorldInstanceIds,
        dispatchRequestWorldInstanceIds: dispatchRequestWorldInstanceIds,
        selfUser: selfUser,
        setSelfUser: setSelfUser,
        setSelfProfile: setSelfProfile,
        showProfileUser: showProfileUser,
        setShowProfileUser: setShowProfileUser,
        showProfileUserLoading: showProfileUserLoading,
        setShowProfileUserLoading: setShowProfileUserLoading,
        usersPageSize: usersPageSize,
        setUsersPageSize: setUsersPageSize,
        worldsPageSize: worldsPageSize,
        setWorldsPageSize: setWorldsPageSize,
        compactView: compactView,
        setCompactView: setCompactView,
        sortPerInstance: sortPerInstance,
        setSortPerInstance: setSortPerInstance,
        showOnlyUserExistsTab: showOnlyUserExistsTab,
        setShowOnlyUserExistsTab: setShowOnlyUserExistsTab,
        friendsFirstFetched: friendsFirstFetched,
        setFriendsFirstFetched: setFriendsFirstFetched,
        friendsFetchRestInterval: friendsFetchRestInterval,
        setFriendsFetchRestInterval: setFriendsFetchRestInterval,
        setFriendsFetchRestIntervalMax: setFriendsFetchRestIntervalMax,
        friendsOfflineFetchRestInterval: friendsOfflineFetchRestInterval,
        setFriendsOfflineFetchRestInterval: setFriendsOfflineFetchRestInterval,
        setFriendsOfflineFetchRestIntervalMax: setFriendsOfflineFetchRestIntervalMax,
        pages: pages,
        setPages: setPages,
        page: page,
        setPage: setPage,
        friendsLoadingState: friendsLoadingState,
        setFriendsLoadingState: setFriendsLoadingState,
        friendsLoading: friendsLoading,
        onlineFriendsInWorldInstanceIndexes: onlineFriendsInWorldInstanceIndexes,
        setOnlineFriendsInWorldInstanceIndexes: setOnlineFriendsInWorldInstanceIndexes,
        onlineFriendsLoading: onlineFriendsLoading,
        setOnlineFriendsLoading: setOnlineFriendsLoading,
        onlineFriends: onlineFriends,
        setOnlineFriends: setOnlineFriends,
        onlineFriendsMax: onlineFriendsMax,
        setOnlineFriendsMax: setOnlineFriendsMax,
        offlineFriendsLoading: offlineFriendsLoading,
        setOfflineFriendsLoading: setOfflineFriendsLoading,
        offlineFriends: offlineFriends,
        setOfflineFriends: setOfflineFriends,
        offlineFriendsMax: offlineFriendsMax,
        setOfflineFriendsMax: setOfflineFriendsMax,
        favoriteFriendsLoading: favoriteFriendsLoading,
        setFavoriteFriendsLoading: setFavoriteFriendsLoading,
        favoriteFriends: favoriteFriends,
        setFavoriteFriends: setFavoriteFriends,
        favoriteFriendsMax: favoriteFriendsMax,
        setFavoriteFriendsMax: setFavoriteFriendsMax,
        notesLoading: notesLoading,
        setNotesLoading: setNotesLoading,
        notesFetched: notesFetched,
        setNotesFetched: setNotesFetched,
        userDefinedGroups: userDefinedGroups,
        setUserDefinedGroups: setUserDefinedGroups,
        userDefinedGroupsFetchRequest: userDefinedGroupsFetchRequest,
        setUserDefinedGroupsFetchRequest: setUserDefinedGroupsFetchRequest,
        editUserDefinedGroupUsers: editUserDefinedGroupUsers,
        setEditUserDefinedGroupUsers: setEditUserDefinedGroupUsers,
        editingUserDefinedGroup: editingUserDefinedGroup,
        searchUsersLoading: searchUsersLoading,
        setSearchUsersLoading: setSearchUsersLoading,
        searchUsers: searchUsers,
        setSearchUsers: setSearchUsers,
        editSelfProfile: editSelfProfile,
        setEditSelfProfile: setEditSelfProfile,
        tempProfile: tempProfile,
        setTempProfile: setTempProfile,
        selfProfileSaving: selfProfileSaving,
        setSelfProfileSaving: setSelfProfileSaving,
        editTags: editTags,
        setEditTags: setEditTags,
        setUserProfileTag: setUserProfileTag,
        tagsSaving: tagsSaving,
        setTagsSaving: setTagsSaving,
        requestUpdateUserInfo: requestUpdateUserInfo,
        setRequestUpdateUserInfo: setRequestUpdateUserInfo,
        updatedUserInfo: updatedUserInfo,
        setUpdatedUserInfo: setUpdatedUserInfo,
        requestEditProfilePrivateInfo: requestEditProfilePrivateInfo,
        setRequestEditProfilePrivateInfo: setRequestEditProfilePrivateInfo,
        updatedEditProfilePrivateInfo: updatedEditProfilePrivateInfo,
        setUpdatedEditProfilePrivateInfo: setUpdatedEditProfilePrivateInfo,
        editProfilePrivateInfo: editProfilePrivateInfo,
        setEditProfilePrivateInfo: setEditProfilePrivateInfo,
        tempPrivateMemo: tempPrivateMemo,
        setTempPrivateMemo: setTempPrivateMemo,
        tempPrivateTags: tempPrivateTags,
        setTempPrivateTags: setTempPrivateTags,
        profilePrivateInfoSaving: profilePrivateInfoSaving,
        setProfilePrivateInfoSaving: setProfilePrivateInfoSaving,
        applyProfilePrivateInfo: applyProfilePrivateInfo,
        searchParams: searchParams,
        setSearchParams: setSearchParams,
        setSearchTags: setSearchTags,
        savedSearchParams: savedSearchParams,
        setSavedSearchParams: setSavedSearchParams,
        savedSearchParamsLoading: savedSearchParamsLoading,
        setSavedSearchParamsLoading: setSavedSearchParamsLoading,
        showSearchForm: showSearchForm,
        setShowSearchForm: setShowSearchForm,
        fetchTagsState: fetchTagsState,
        setFetchTagsState: setFetchTagsState,
        requestFetchTags: requestFetchTags,
        tags: tags,
        setTags: setTags,
        tagLoading: tagLoading,
        setTagLoading: setTagLoading,
        tagsFetchedAt: tagsFetchedAt,
        setTagsFetchedAt: setTagsFetchedAt,
        tagValue: tagValue,
        setTagValue: setTagValue,
        tagSuggestions: tagSuggestions,
        setTagSuggestions: setTagSuggestions,
        playerModerations: playerModerations,
        setPlayerModerations: setPlayerModerations,
        playerModerationsFetchRequest: playerModerationsFetchRequest,
        setPlayerModerationsFetchRequest: setPlayerModerationsFetchRequest,
        getUserFirstUnmuteAt: getUserFirstUnmuteAt,
        worldSearchParams: worldSearchParams,
        setWorldSearchParams: setWorldSearchParams,
        savedWorldSearchParams: savedWorldSearchParams,
        setSavedWorldSearchParams: setSavedWorldSearchParams,
        savedWorldSearchParamsLoading: savedWorldSearchParamsLoading,
        setSavedWorldSearchParamsLoading: setSavedWorldSearchParamsLoading,
        searchGroupName: searchGroupName,
        setSearchGroupName: setSearchGroupName,
        groups: groups,
        setGroups: setGroups,
        groupsFirstFetched: groupsFirstFetched,
        setGroupsFirstFetched: setGroupsFirstFetched,
        groupsLoading: groupsLoading,
        setGroupsLoading: setGroupsLoading,
        groupsFetchRequest: groupsFetchRequest,
        setGroupsFetchRequest: setGroupsFetchRequest,
        groupUsers: groupUsers,
        setGroupUsers: setGroupUsers,
        groupUsersLoading: groupUsersLoading,
        setGroupUsersLoading: setGroupUsersLoading,
        groupUsersFetchRequest: groupUsersFetchRequest,
        setGroupUsersFetchRequest: setGroupUsersFetchRequest,
        clearGroupUsers: clearGroupUsers,
        contentsSeriesList: contentsSeriesList,
        setContentsSeriesList: setContentsSeriesList,
        contentsItems: contentsItems,
        setContentsItems: setContentsItems,
        contentsSeriesFirstFetched: contentsSeriesFirstFetched,
        setContentsSeriesFirstFetched: setContentsSeriesFirstFetched,
        contentsItemsFirstFetched: contentsItemsFirstFetched,
        setContentsItemsFirstFetched: setContentsItemsFirstFetched,
        contentsSeriesLoading: contentsSeriesLoading,
        setContentsSeriesLoading: setContentsSeriesLoading,
        contentsItemsLoading: contentsItemsLoading,
        setContentsItemsLoading: setContentsItemsLoading,
        contentsSeriesFetchRequest: contentsSeriesFetchRequest,
        setContentsSeriesFetchRequest: setContentsSeriesFetchRequest,
        contentsItemsFetchRequest: contentsItemsFetchRequest,
        setContentsItemsFetchRequest: setContentsItemsFetchRequest,
        allPrivateMemoSynced: allPrivateMemoSynced,
        syncPrivateMemo: syncPrivateMemo,
        setSyncPrivateMemo: setSyncPrivateMemo,
        syncingPrivateMemoUserModel: syncingPrivateMemoUserModel,
        setSyncingPrivateMemoUserModel: setSyncingPrivateMemoUserModel,
        backupDb: backupDb,
        lang: lang,
        setLang: setLang,
    };
    (0, useGetFriends_1.useGetFriends)(state);
    (0, useGetNeedWorlds_1.useGetNeedWorlds)(state);
    (0, useGetMissingWorlds_1.useGetMissingWorlds)(state);
    (0, useGetWorldDetail_1.useGetWorldDetail)(state);
    (0, useGetWorldInstance_1.useGetWorldInstance)(state);
    (0, useGetGroups_1.useGetGroups)(state);
    (0, useGetGroupUsers_1.useGetGroupUsers)(state);
    (0, useFirstGetGroups_1.useFirstGetGroups)(state);
    (0, useGetContentsSeries_1.useGetContentsSeries)(state);
    (0, useGetContentsItems_1.useGetContentsItems)(state);
    (0, useFetchTags_1.useFetchTags)(state);
    (0, useGetPlayerModerations_1.useGetPlayerModerations)(state);
    (0, useGetUserDefinedGroups_1.useGetUserDefinedGroups)(state);
    (0, useSyncPrivateMemos_1.useSyncPrivateMemos)(state);
    (0, useUpdateUserInfo_1.useUpdateUserInfo)(state);
    (0, useUpdateUserInfo_1.useRequestEditPrivateMemo)(state);
    return state;
};
exports.useAllState = useAllState;
